@import "../../../styles/variables";

.modal{
  width: 550px;
  max-height: 90vh;
  overflow-y: auto;
}
.root {
  margin: 16px 0;
  display: flex;
}

.details {
  margin-left: 16px;
  flex: 1;
}

.name {
  font-size: 18px;
  font-weight: 500;
}

.label {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}

.label>div{
  margin-bottom: 5px;
}

.edit {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #000;
  margin-top: 15px;
}

.phone{
  color: #000;
}

.avatar {
  object-fit: cover;
  height: 85px !important;
  width: 85px !important;
  margin-right: 0 !important;
  border-radius: 40px;
}
