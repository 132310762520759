@import "../../styles/variables";

.root {
  display: grid;
  grid-template-columns: 320px auto;
  grid-auto-rows: 1fr;
  gap: 9px;
  flex-grow: 1;
  max-height: calc(100vh - 60px);
  padding: 0px 8px 4px;
}

.panel {
  width: 320px;
  background: #f9f9fd;
  border-radius: $border-radius;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    min-height: 67px;
    border-radius: $border-radius $border-radius 0 0;

    background-color: #ececf4;
    padding: 0 10px;

    &.--dark {
      background-color: $primary;
      color: #fff;
    }
  }

  &__inbox_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #222222;
    margin-left: 10px;
  }

  &__vehicle {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 4px;

    img {
      margin-right: 4px;
    }
  }

  &__endpoints {
    margin: 0;
    white-space: nowrap;
    font-size: 12px;
    line-height: 14.32px;
    max-width: 245px;
    overflow: hidden;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #222222;

    }
  }

  &__change_vehicle {
    text-decoration: underline;
    cursor: pointer;
  }

  .assign{
    box-shadow: 0px -1px 0px #dbdbdb, 0px 1px 0px #dbdbdb;
  }

  &__filtersCon{
    box-shadow: 0px -1px 0px #dbdbdb, 0px 1px 0px #dbdbdb;
  }

  &__filters {
    background: #f9f9fd;

    min-height: 60px;
    position: relative;
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  &__start {
    display: flex;
    align-items: center;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
      margin-right: 14px;
    }

    input {
      border-radius: $border-radius;
      // max-width: 90px;
      color: $primary-l;
      // padding-left: 25px;
    }
  }

  &__unassign {
    padding: 0 15px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    background-color: #fe7062 !important;
    border-radius: $height-sm !important;
    white-space: nowrap;

    &:disabled {
      background-color: $primary-low !important;

      &::before {
        display: none;
      }
    }
  }

  &__content {
    padding: 5px 10px;
    flex-grow: 1;
    overflow: auto;
    box-sizing: border-box;

    @media print {
      padding: 0;
    }

    &.--assigned {
      // height: calc(100% - 302px);
    }
  }

  &__bottom {
    display: flex;
    padding: 10px 11px 20px;
    margin-top: auto;

    >button {
      flex-grow: 1;
    }
  }

  &__assigned_buttom {
    background: #ffffff;
    border: 1px solid #ececf4;
    box-sizing: border-box;
    border-radius: 8px 8px 0px 0px;
    margin-top: auto;

    display: flex;
    flex-direction: column;
    padding: 16px 11px 10px;
  }

  &__assigned_summary {
    display: flex;
    justify-content: space-between;
    padding: 2px 2px 12px;
    border-bottom: 1px solid #eeeeee;
  }

  &__deliveries_counter {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: $primary;
  }

  &__deliveries_print_action {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 4px;
    border-radius: 3px;
    cursor: pointer;
    margin: -2px auto 0 10px;
    outline: none;
    background: transparent;
    transition: .2s;

    &:hover {
      background-color: darken($gray-6, 4);
      color: $primary;
    }
  }

  &__deliveries_end_time {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;

    img {
      margin-right: 4px;
      width: 13px;
      height: 13px;
    }

    span {
      color: $primary-l;
      margin-right: 4px;
    }
  }

  &__assigned_stats {
    display: flex;
    margin-top: 12px;
  }

  &__assigned_actions {
    padding: 10px 0;
  }

  &__assigned_stat {
    display: grid;
    flex-grow: 1;
    grid-template-columns: 16px auto;
    gap: 0 6px;
    grid-template-areas:
      "icon metric"
      ". disc";
    margin-bottom: 16px;

    img {
      grid-area: icon;
    }

    span:nth-child(2) {
      grid-area: metric;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      display: flex;
      align-items: center;

      color: $primary;
    }

    span:last-child {
      grid-area: disc;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;

      display: flex;
      align-items: center;

      color: $primary-l;
    }
  }

  &__calc {
    display: flex;
    margin-bottom: 10px;

    >button {
      flex-grow: 1;
    }
  }
}

.list {
  padding-top: 50px;

  &_total {
    font-size: 18px;
    text-align: right;
    padding: 0 10px;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  &>a {
    text-decoration: none;
    color: inherit;
  }



  &__item,
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    padding: 0px 10px;
    transition: 0.3s background;
    border-bottom: 1px solid #dbdbdb;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .list__id{
    // width: 160px;
  }
  .list__count{
    width: 120px;
    text-align: right;
  }

  &__item_header:hover {
    background: rgba(0, 0, 0, 0);
  }
}
.list__item_header>p{
  // width: 120px;
}
.list__item_header>p:nth-child(3){
  text-align: right;
}
.list__item{
  gap: 30px;
}


.delivery_image>img{
  width: 100%;

}

.list_wrapper {
  min-width: 650px;
  overflow-y: scroll;
  max-height: 90vh;
}
.orderNote {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  text-align: start;
  resize: none;
}
.signatureText {
  margin-top: 20px;
}
.noteFormWrapper {
  margin-top: 50px;
}
.noteInput {
  margin-top: -10px;
  font-size: 15px;
  padding: 7px;
  font-family: SF Pro Display;
  font-style: normal;
}
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}
.signatureImage{
  width: 100%;
  aspect-ratio: 1/0.6;
}
.spinner_loading{
  display: flex;
  height: 70vh;
  width: '100%';
  align-items: center;
  justify-content: center;
}

.panel__unassign{
  margin-left: auto;
  display: flex;
}

.datetime{
  color: #000;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 0.6;
  font-weight: 500;
}
.clock_icon{
  margin-right: 3px;
  scale: 1.2;
}
.checkbox {
  margin-top: 1px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  // box-shadow: 0px -1px 0px #dbdbdb;
  width: 135px;

  input {
    margin-left: 7px;

    @media print {
      display: none;
    }
  }

  label {
    font-size: 17px;
    color: #000000;
    opacity: 0.6;

    @media print {
      font-size: 18pt;
      color: #000;
      // padding-bottom: 14pt;
    }
  }
}

.assignCheck{
  box-shadow: 0px 0px 0px #dbdbdb;
  input {
    margin-left: 8px;

    @media print {
      display: none;
    }
  }
}
.Time_Select{
  .Select {
    &__control {
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 8px !important;
      min-width:  120px;
    }
  }
}
.timer{
  margin-left: 6px;
}
.modalHeader{
  text-align: center;
  margin-top: 30px;
  font-size: 20px;
}
.delete{
  margin-left: auto;
  margin-top: 20px;
}
.cancel{
  margin-bottom: 20px;
}
.delete_wrapper{
  min-width: 450px;
}
