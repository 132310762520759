@import "../../styles/variables";

.pagination {
  display: flex;
  // gap: 10px;
  align-items: center;
  margin-top: 30px;
  padding: 10px;
  &__navlink {
    display: inline-block;
    padding: 10px 8px;
    text-decoration: none;
    border-radius: 8px;
    transform: scale(.7, 1.2);
    color: $primary-l;
    cursor: pointer;
  }
  &__list {
    display: flex;
    align-items: center;
    gap: 12px;
    list-style: none;
    margin: 0 16px;
    padding: 0;
  }
  &__link {
    display: inline-block;
    padding: 10px 14px;
    text-decoration: none;
    border-radius: 8px;
    cursor: pointer;
    color: $primary-l;
  }
  &__link.active {
    font-weight: 700;
    color: $blue-2;
    background-color: #eee;
  }
  &__separator {
    margin: 0 10px;
    color: $primary-l;
    letter-spacing: .2em;
    font-size: 18px;
    line-height: 0;
    padding-bottom: 10px;
  }
}