@import "../../styles/variables";

.root {
  margin: 12px 6px;
  width: calc(100% - 12px);
  box-sizing: border-box;
}

.table {
  margin: 0 15px;
  overflow-x: scroll;
}

.head {
  display: grid;
  margin-bottom: 10px;
  min-width: 110px;
  padding: 0 5px;
  font-weight: 600;
  &.--center {
    justify-self: center;
    text-align: center;
    justify-content: center;

  }

  &.--right {
    justify-self: right;
  }
}

.row {
  display: grid;
  padding: 10px 8px;
  border-bottom: 1px #eeeeee solid;

}



.data_row {
  &:hover {
    background-color: #eeeeee;
  }
  cursor: pointer;
}

.header_cell {
  display: flex;
  align-items: center;
  cursor: pointer;

}
.refill_no{
  margin-left: 35px;
}
.amount_due{
  margin-left: 35px;

}
.rx_no{
  min-width: 100px;
  max-width: 120px;
}
.sync_time{
  text-align: center;
  min-width: 85px;
  max-width: 100px;
  line-height: 22px;
}
.bill_time{
  text-align: center;
  min-width: 85px;
  max-width: 100px;
  line-height: 22px;
}

.number{
  // justify-self: center;
}


.cell {
  margin-top: 3px;
  overflow-wrap: anywhere;
  padding: 0 5px;
  min-width: 110px;

  &.--center {
    justify-self: center;
    text-align: center;
    justify-content: center;
  }

  &.--right {
    justify-self: right;
  }
}

.status {
  min-width: 130px;
  max-width: 140px;
  padding: 0 0px;
}

.border {
  grid-column-start: 1;
  grid-column-end: -1;
}

.sort_button {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5px 12px;
  transition: all 250ms ease-in-out;

  &.--active {
    background-image: url("./icons/arrow_down.svg");
  }

  &.--asc {
  }

  &.--desc {
    transform: rotate(180deg);
  }
}

.loader,
.empty {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .cell {
    padding: 0;
    min-width: 120px;

  &.--center {
    text-align: center;
  }

  }
  .head{
    display: flex !important;
    align-items: center;
    min-width: 120px !important;
  }

  .header_cell{
    min-width: 120px;
  }
  .status {
    min-width: 120px !important;
  }
  .refill_no{
    margin-left: 0px;
  }
  .amount_due{
    margin-left: 0px;
  }
  .rx_no{
    min-width: 120px;
  }
  .sync_time{
    min-width: 120px;
    line-height: 22px;
  }
  .bill_time{
    text-align: left;
    min-width: 120px;
    line-height: 22px;
  }
}
