.container{
    max-width: 900px;
    align-self: center;
    width: 100%;
    // margin-top: auto;
    margin-bottom: auto;
    display: flex;
    gap: 20px;
}
.actionWrapper{
    justify-content: center;
    margin-top: 20px;
}
.title{
   text-align: center;
}
.success{
    text-align: center;
    margin: 30px;
}
.successImg{
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}
.spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.stores{
    text-align: center;
}
.storeImg{
    width: 120px;
    height: 40px;
    margin: 5px;
}
.label{
    margin-top: 8px;
    margin-bottom: 10px;
    font-size: 16px;
    margin-left: 50px;
}
.logo{
    height: 100px;
    overflow: hidden;
    
}
.text{
    font-size: 18px;
    margin-top: 5px;
    margin-left: 50px;
    margin-bottom: 15px;
}
.noSuccess{
    margin-left: 0;
}
.noSuccessText{
    margin-left: 0;
}

@media screen and (max-width: 700px){
    .container{
        flex-direction: column;
    }
    
}