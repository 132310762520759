@import '../../styles//variables';

.wrapper {
  display: flex;
  flex-direction: column;
  label {
    display: block;
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 17px;

    color: #000000;
  }
  .error {
    position: absolute;
    bottom: -25px;
    left: 0;
    font-size: 12px;
    line-height: 14px;
    color: #ff3b24;
  }
}

.google_place_auto_complete {
  display: flex;
  align-items: center;
  border: 1px solid $primary-low;
  box-sizing: border-box;
  border-radius: 3px;
  padding-left: 7px;
  width: 100%;
  height: $height-md;
  &:focus,
  &:active {
    outline: none;
  }
}

.input{
  width: 100%;
  display: flex;
  border-width: 0;
  font-size: 14px;
  line-height: 17px;
  font-style: normal;
  font-weight: normal;
  &:focus,
  &:active {
    outline: none;
  }
}
