@import "../../styles/variables";

.header {
  margin-top: 42px;
  margin-bottom: 10px;
  font-size: 24px;
}

.account_warning {
  display: flex;
  align-items: center;

  height: 73px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 24px;
  margin-bottom: 3px;
  background-color: $gray-6;

  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #000000;

  &::before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background-image: url("./icons/shield.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 24px;
  }
}

.section_header {
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #000000;
  padding-bottom: 3px;
  margin-top: 23px;

  border-bottom: 1px solid #cccccc;
}

.list_wrapper {
  min-width: 550px;
  min-height: 200px;
  max-height:80vh;
  overflow-y: scroll;
}
.orders_wrapper{
  min-width: 700px;
  max-height:80vh;
  overflow-y: scroll;
  min-height: 200px;
}
.modalDesc {
  margin-top: 50px;
}
.optionWrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.container {
  cursor: pointer;
  margin-top: 10px;
  padding: 7px;
}
.radioButton {
  size: 40px;
}
.radioOption {
  cursor: pointer;
  margin-top: 10px;
  padding: 7px;
  display: flex;
  border-radius: 8px;
  border: 1px solid  $gray-2;
}
.radioButtonText {
  margin-left: 5px;
  font-size: larger;
  margin: 4px;
  margin-left: 10px;
}
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
}
.row{
  display: flex;
  justify-content: space-between;
}

.item_header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  letter-spacing: .05em;
  margin-bottom: 15px;
}
.card{
  margin-top: 20px;
  padding: 10px 12px;

  border-radius: 3px;
  transition: .2s;
  background-color: desaturate(darken($gray-6, 1), 4);
}

.card:hover {
  background-color: desaturate(darken($gray-6, 7), 8);
}

.orders{
  width: 100%;
  margin: 0;
  margin-top: 20px;
}

.ordersWrapper{
  margin: 0;
}
.addCard{
  color: $highlight;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
}