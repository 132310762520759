@import "../../styles/variables";

.root {
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: $border-radius;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 5px 0px;
  padding: 16px 15px 12px 20px;
}

.bottom {
  display: flex;
  justify-content: space-between;
}

.author {
  display: flex;
  align-items: center;

  &__name {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
  }
}

.datetime {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: $primary-l;
}

.row_name{
  display:flex;
  flex-direction: row;
}

.route_id{
  margin-left: 10px;
  text-decoration: underline;
  color:#0071B3;
  cursor: pointer;
  font-size: 16px;
}

.transaction_id{
  margin-left: 10px;
  text-decoration: underline;
  color:#0071B3;
  cursor: pointer;
  font-size: 14px;
}
