@import "../../styles/variables";

.overlay {
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: $primary, $alpha: 0.2);
}

.modal {
  position: relative;
  min-width: 360px;
  max-width: 600px;
  border: none;
  background-color: $white;
  border-radius: $border-radius;
  padding: 32px 32px 22px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);

  &:focus-visible {
    outline: none;
  }
}

.close_icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 38px;
  height: 38px;
  cursor: pointer;
}
