@import "../../../styles/variables";

.accordion {
display: flex;
flex-direction: column;
height: 100%;
justify-content: space-between;

padding: 0px 20px;
padding-bottom: 10px;

overflow-y: scroll;
  &__item_header {
    padding: 10px 12px;
    background-color: desaturate(darken($gray-6, 1), 4);
    border-radius: 3px;
    font-weight: 600;
    letter-spacing: .05em;
    cursor: pointer;
    transition: .2s;

    &:hover {
      background-color: desaturate(darken($gray-6, 7), 8);
    }
  }

  &__item_content {
    border: 1px solid darken($gray-6, 5);
    padding: 0 16px 16px 16px;
  }

  &__item_header_icon_rotated {
    transform: rotate(90deg);
  }
}

.accordion__inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
}

.switchForm {
  margin-top: 25px;
  margin-bottom: 10px;
}

.switchContainer {
  display: flex;
  justify-content: flex-end;
}

.delete_modal{
  padding-top: 0;
}

.label {
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
}
.warningText {
  display: block;
  margin-top: 15px;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.actionWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.buttonWrap {

}
.list_wrapper {
  min-width: 550px;
  max-height: 600px;
}
.modalWrapper {
  margin-top: 30px;
}
.cancelButton {
  margin-right: 15px;
}

.googleInput{
  margin-top: 2px;
}


