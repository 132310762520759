@import "../../styles/variables";

.root {
  background: $background;
  border: 1px solid $primary-low;
  box-sizing: border-box;
  border-radius: $border-radius;
  padding: 8px 0;
  margin-top: 25px;
  flex-grow: 1;
}
