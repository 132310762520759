@import "../../styles/variables";

.Select {
  &__control {
    box-sizing: border-box;
    border-color: $primary-low !important;
    border-radius: 3px !important;

  }

  &__indicator-separator {
    display: none !important;
  }

  &__dropdown-indicator {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    svg path {
      fill: #797979;
    }
  }
}

.Select-sm {
  .Select__control {
    min-height: $height-sm !important;
  }

  .Select__option{
    min-height: $height-sm !important;
  }
}

.Select-md {
  .Select__control {
    min-height: $height-md !important;
  }
  .Select__option{
    min-height: $height-md !important;
  }
}

.Select-md {
  .Select__control {
    min-height: $height-md !important;
  }
  .Select__option{
    min-height: $height-md !important;
  }
}

.error{
  .Select__control {
    border-color: $error !important;
  }
}
