@import "../../../../styles/variables";

.form {
  max-height: 0;
  // flex-grow: 1;

  box-sizing: border-box;
  // overflow: hidden;

  // transition: all 250ms ease-in-out;

  &.--expanded {
    max-height: 450px;
  }

  &__content {

    padding: 26px 10px 16px 15px;

    input,
    .Select__control {
      border-radius: 8px !important;
    }
  }
}

.label{
  width: 70px;
  font-size: 14px;
  color: $primary-l;
}

.formRow{
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}

.option {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}

.actions {
  display: flex;
  grid-column-start: 2;
  margin-top: 20px;

  button + button {
    margin-left: 6px;
  }
}
.driverStatusWrap {
  margin-left: 5px;
}
.driverStatus{
  font-size: small;
  color: #3dcc4a;
}

.icon_wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 15px;
  left: 6px;
  z-index: 1;
}
.select{
  z-index: 2;
  width: 100%;
}

.google_input {
  border: 1px solid $primary-low;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  // background-color: #fff;
  margin-left: 32px;
}

.root{
  width: 100%;
  position: relative;
}
.calendarRoot{
  width: 100%;
}
.wrapper{
  margin-top: 0;
  display: flex;
  background-color: white;
}
