@import "../../styles/variables";

.header {
  margin-top: 42px;
  margin-bottom: 10px;
  font-size: 24px;
}

.account_warning {
  display: flex;
  align-items: center;

  height: 73px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 24px;
  margin-bottom: 3px;
  background-color: $gray-6;

  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #000000;

  &::before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background-image: url("./icons/shield.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 24px;
  }
}

.section_header {
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #000000;
  padding-bottom: 3px;
  margin-top: 23px;

  border-bottom: 1px solid #cccccc;
}
.eyeIcon{
  position: absolute;
  z-index: 1;
  right: 5px;
  top:55px;
  opacity: 0.5;
  cursor: pointer;
}
.delete{
  margin-left: auto;
}
.tooltipContainer{
  position: relative;
  display: inline-block;
  &:hover .tooltip{
    display: block;
  }
  cursor: pointer;
  color: #2d00f7;
  font-weight: 500;
}
.tooltip{
  position: absolute;
  top: 0;
  right: 0;
  background-color: #000000;
  color: #ffffff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  display: none;
  z-index: 10;
  width: 250px;
}
.infoIcon{
  width: 18px;
  height: 18px;
  margin-top: 4px;
}