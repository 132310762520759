@import "../../styles/variables";

.ScriptStatusSelect {
  &__control {
    display: inline-flex !important;
    min-height: 30px !important;
    min-width: 120px;
    border-radius: 30px !important;
    border: none !important;
    &--is-focused {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    svg {
      fill: $white !important;
    }
  }

  &__value-container {
    display: flex !important;
    flex-wrap: nowrap !important;
    padding: 0 10px !important;

  }

  &__single-value {
    font-size: 14px;
    color: $white !important;
  }
}

.missing_transfer,
.no_script_available {
  .ScriptStatusSelect__control {
    background-color: #f9dde5;
  }
}

.canceled {
  .ScriptStatusSelect__control {
    background-color: #ff574b;
  }
}
.picked_up {
  .ScriptStatusSelect__control {
    background-color:#6ECF97;
  }
}
.in_progress,.request_transfer {
  .ScriptStatusSelect__control {
    background-color: #f5e274;
  }
}

.ready_for_dispatch{
  .ScriptStatusSelect__control {
    background-color: #d6b6fb;
  }
}

.paid,

.billed,
.delivered,
.otc_status,
.ready_for_delivery,
.completed {
  .ScriptStatusSelect__control {
    background-color: #6ECF97;
  }
}
.delivery_partner{
  .ScriptStatusSelect__control {
    background-color: #b9baf9;
  }
}
.incomplete{
  .ScriptStatusSelect__control {
    background-color: #eeeeee;
  }
  
}
.archived{
  .ScriptStatusSelect__control {
    background-color: rgb(200, 200, 200);
  }
}
.new{
  .ScriptStatusSelect__control {
    background-color:   #200589;
  }
}
