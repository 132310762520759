.root {
  max-width: 680px;
  flex-basis: 680px;
}

.button_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  text-decoration: none;

  color: #0068ed;
}

.signup {
  margin: 50px 0;
  text-align: center;
}

.message {
  max-width: 450px;
  margin: 50px auto;
}
.row{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row>div{
  width: 47%;
}

.eyeIcon{
  position: absolute;
  z-index: 1;
  right: 5px;
  top:32px;
  opacity: 0.5;
  cursor: pointer;
}

.actionWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 10px;
  align-items: center;
}
.modal {
  width: 500px;
}
.modalText {
  margin-top: 20px;
  font-size: 16px;
  line-height: 19px;
}
.resend{
  cursor: pointer;
  color: #0068ed;
  font-weight: 500;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 16px;
}
