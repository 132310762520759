@import "../../styles/variables";

.root {
  position: relative;
  margin-top: 22px;
}

.label {
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 17px;

  color: #000000;
}

.error {
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 12px;
  line-height: 14px;
  color: $error;
}

